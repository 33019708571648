import React from "react";
import Input from "../Global/Input";
import { PrimaryButton } from "../Global/Button";
import AudioAnimation from "../../assets/audio-animation.png";

export default function PatientInformation({
  patientName,
  setPatientName,
  startRecording,
  setPauseRecording,
  setIsRecording,
  setSelectedLanguage,
}) {
  return (
    <div className="flex gap-2 flex-col text-center items-center justify-center">
      <div className="flex item-center justify-center">
        <img src={AudioAnimation} alt="animation" />
      </div>
      <p className="font-semibold">Start by saving patient’s name</p>
      <div>
        <p className="text-sm text-customBlack-text ">
          Any recordings done here will be saved in recordings
        </p>

        <p className="text-sm text-customBlack-text ">section on the left.</p>
        <p className="text-sm text-customBlack-text ">
          Hit the record button once you type in the patient name
        </p>
      </div>
      <div className="w-full">
        <div className="flex">
          <select
            id="language"
            name="Language"
            className="w-full p-2.5 mb-1.5 mt-1.5 border border-gray-300 rounded-lg h-11"
            onChange={(e) => setSelectedLanguage(e.target.value)}
          >
            <option value="en-US">English</option>
            <option value="fr-FR">French</option>
            <option value="ur-PK">Urdu</option>
            <option value="ar-SA">Arabic</option>
            <option value="zh-CN">Mandarin</option>
            <option value="yue-HK">Cantonese</option>
            <option value="pa-IN">Punjabi</option>
            <option value="pt-BR">Portugese</option>
            <option value="es-ES">Spanish</option>
          </select>
        </div>

        <Input
          label="Patient's name"
          type="text"
          placeholder="Enter patients name"
          value={patientName}
          handleOnChange={(e) => setPatientName(e.target.value)}
          isRequired={true}
        />
        <PrimaryButton
          label={"Record"}
          onClick={() => {
            startRecording();
            setPauseRecording(true);
            setIsRecording(true);
          }}
          isDisabled={!!!patientName}
        />
      </div>
    </div>
  );
}
