import React, { useState } from "react";
// import Settings from "../assets/settings.svg";
// import Notification from "../assets/notification.svg";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import "../../styles/Navbar.css";
import Logo from "../../assets/logo.png";

const Navbar = () => {
  return (
    <nav className="flex items-center justify-between p-4 border-b border-customGrey-primary">
      <Link to="/">
        <img src={Logo} alt="Company Logo" className="h-12" />
      </Link>

      <div className="flex items-center space-x-4">
        <Link
          to="/signup"
          className="btn-primary border border-customBlack-primary"
        >
          Register
        </Link>
        <Link
          to="/login"
          className="btn-secondary border border-customBlue-secondary"
        >
          Login
        </Link>
      </div>
    </nav>
  );
};

export default Navbar;
