import React from "react";
import { PrimaryButton, SecondaryButton } from "../Global/Button";
import RecordIcon from "../../assets/record-icon.png";
import RecordGreenIcon from "../../assets/record-icon-green.png";

export default function TranscriptSection({
  selectedRecord,
  startRecording,
  pauseRecording,
  userTranscript,
  formattedPromptSOAP,
  stopRecording,
  selectedOption,
  handleOptionChange,
  handleSubmit,
  handleReSubmit,
  resetRecordingState,
  setSelectedSpecialization,
  handleTranscriptChange,
}) {
  console.log("🚀 ~ selectedRecord:", selectedRecord);

  return (
    <div
      className={`w-full 
  ${formattedPromptSOAP ? "md:w-[40%]" : "md:w-[66%] "}
  border-r h-screen `}
    >
      {/* {selectedRecord?.id ? (
        <p className="p-2"> {selectedRecord?.transcript}</p>
      ) : (
      )} */}
      <textarea
        value={userTranscript}
        className="border-none w-full"
        rows="10"
        placeholder="Transcription will appear here..."
        onChange={handleTranscriptChange}
      />

      {!selectedRecord?.id && (
        <div className="flex flex-col items-center">
          <div className="flex gap-2 items-center">
            <img
              src={pauseRecording ? RecordGreenIcon : RecordIcon}
              alt="record icon"
              className="cursor-pointer"
              onClick={pauseRecording ? stopRecording : startRecording}
            />
            <p className="text-lg">
              {!pauseRecording
                ? "Recording Paused(Click button to start recording)"
                : "Currently Recording(Click button to stop recording)"}
            </p>
          </div>
          <p className="text-customBlack-text">
            {pauseRecording
              ? "Press the record icon to stop recording"
              : "Press the record icon to start recording"}
          </p>

          <div className="flex gap-2">
            <div className="flex items-center mb-4">
              <input
                type="radio"
                id="SOAP"
                name="options"
                value="SOAP"
                checked={selectedOption === "SOAP"}
                onChange={handleOptionChange}
                className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300"
              />
              <label
                htmlFor="SOAP"
                className="ml-2 block text-sm text-gray-900"
              >
                SOAP Notes
              </label>
            </div>
            <div className="flex items-center mb-4">
              <input
                type="radio"
                id="consultation"
                name="options"
                value="consultation"
                checked={selectedOption === "consultation"}
                onChange={handleOptionChange}
                className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300"
              />
              <label
                htmlFor="consultation"
                className="ml-2 block text-sm text-gray-900"
              >
                Consultation Notes
              </label>
            </div>
          </div>
          {selectedOption === "consultation" && (
            <div className="flex">
              <select
                id="occupation"
                name="occupation"
                className="w-full p-2.5 mb-1.5 mt-1.5 border border-gray-300 rounded-lg h-11"
                onChange={(e) => setSelectedSpecialization(e.target.value)}
              >
                <option value="">Select Specialization</option>
                <option value="internal_medicine">Internal Medicine</option>
                <option value="general_surgery">General Surgery</option>
                <option value="anesthesiology">Anesthesiology</option>
                <option value="pediatrics">Pediatrics</option>
                <option value="obstetrics_gynecology">
                  Obstetrics & Gynecology
                </option>
                <option value="psychiatry">Psychiatry</option>
                <option value="ophthalmology">Ophthalmology</option>
                <option value="otolaryngology">Otolaryngology (ENT)</option>
                <option value="orthopedic_surgery">Orthopedic Surgery</option>
                <option value="dermatology">Dermatology</option>
              </select>
            </div>
          )}
        </div>
      )}
      <div className="flex gap-2 items-center justify-center">
        {!selectedRecord?.id && (
          <SecondaryButton
            label={"Start new Recording"}
            onClick={resetRecordingState}
          />
        )}
        <PrimaryButton
          label={"Generate the Note"}
          onClick={() =>
            selectedRecord?.id
              ? handleReSubmit(selectedRecord?.id)
              : handleSubmit()
          }
        />
      </div>
    </div>
  );
}
