import React from "react";
import "../../../styles/Features.css";
import Feature1 from "../../../assets/f1.png";
import Feature2 from "../../../assets/f2.png";
import Feature3 from "../../../assets/f3.png";
import Feature4 from "../../../assets/f4.png";
import Feature5 from "../../../assets/f5.png";
import Feature6 from "../../../assets/f6.png";

const Features = () => {
  const features = [
    {
      image: Feature1,
      heading: "Live Transcription",
      description:
        "Capture every detail with real-time transcription of doctor-patient conversations. No more missed information.",
    },
    {
      image: Feature2,
      heading: "Automated SOAP Notes",
      description:
        "Generate comprehensive SOAP notes instantly, allowing you to focus more on patient care and less on paperwork.",
    },
    {
      image: Feature3,
      heading: "HIPAA Compliance",
      description:
        "Ensure the highest level of security and confidentiality with HIPAA-compliant data handling.",
    },
    {
      image: Feature4,
      heading: "Unlimited Recordings",
      description:
        "Enjoy the freedom to record as many conversations as you need without any limits.",
    },
    {
      image: Feature5,
      heading: "Maintain History",
      description:
        "Track and analyze transcription and note-taking efficiency.",
    },
    {
      image: Feature6,
      heading: "Cost Savings",
      description:
        "Benefit from a reduced rate of when multiple doctors join using the same group code.",
    },
  ];

  return (
    <div className="my-10 flex flex-col items-center gap-4">
      <p className="text-center text-base text-customRed-text font-semibold">
        Features
      </p>
      <p className="text-center text-4xl font-semibold">
        Unleash the Power of MD Voice
      </p>
      <p className="text-center text-xl mt-2 text-customBlack-secondary md:w-[47%]">
        Streamline Your Workflow with Cutting-Edge Transcription and Note-Taking
        Features
      </p>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-10 mt-10 md:p-10">
        {features.map((feature, index) => (
          <Feature key={index} {...feature} />
        ))}
      </div>
    </div>
  );
};

const Feature = ({ image, heading, description }) => {
  return (
    <div className="text-center flex flex-col items-center">
      <img src={image} alt={heading} className="mx-auto mb-4 " />
      <h3 className="text-xl font-bold">{heading}</h3>
      <p className="mt-2 md:w-[65%]">{description}</p>
    </div>
  );
};

export default Features;
