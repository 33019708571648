import React, { useState } from "react";
import Open from "../../../assets/open.png";
import Close from "../../../assets/close.png";
const FAQ = () => {
  const faqs = [
    {
      question: "What is MD Voice?",
      answer:
        "MD Voice is an innovative tool that converts doctor-patient conversations into accurate, automated SOAP notes in real-time.",
    },
    {
      question: "How does the live transcription feature work?",
      answer:
        "Simply press record during your consultation, and MD Voice will transcribe the conversation in real-time, displaying the transcript in a dedicated box.",
    },
    {
      question: "What happens after the conversation is transcribed?",
      answer:
        "Once you click submit, MD Voice will convert the transcript into detailed SOAP notes, ready for review and use.",
    },
    {
      question: "Is my data secure?",
      answer:
        "Yes, all data is handled with the highest level of security and is fully HIPAA-compliant to ensure patient confidentiality.",
    },
    {
      question: "Can I use MD Voice if I’m a solo practitioner?",
      answer:
        "Absolutely! We offer a Single Doctor Plan at $75/month, tailored specifically for individual practitioners.",
    },
    {
      question: "How does the Group Plan work?",
      answer:
        "If you have colleagues who also want to use MD Voice, you can share a unique group code with them. Each doctor will pay $50/month, benefiting from the group discount while maintaining individual access and data privacy.",
    },
  ];

  return (
    <div className="flex flex-col items-center max-w-full">
      <h2 className="text-center text-3xl font-bold">
        Frequently asked questions
      </h2>
      <p className="text-center text-lg mt-2">
        Everything you need to know about the product and billing.
      </p>
      <div className="mt-10 md:w-[60%]">
        {faqs.map((faq, index) => (
          <FAQItem key={index} {...faq} />
        ))}
      </div>
    </div>
  );
};

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="border-b py-4">
      <button
        className="w-full text-left flex justify-between items-center"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="font-semibold">{question}</span>
        <span>
          {!isOpen ? (
            <img src={Open} alt={"Open"} className="mx-auto mb-4 " />
          ) : (
            <img src={Close} alt={"Close"} />
          )}
        </span>
      </button>
      {isOpen && (
        <p className="mt-2 font-normal text-customBlack-secondary">{answer}</p>
      )}
    </div>
  );
};

export default FAQ;
