import React, { useState } from "react";
import { PrimaryButton } from "../Global/Button";

export default function GeneratedPromptView({
  formattedSOAP,
  formattedPromptSOAP,
}) {
  const [isCopied, setIsCopied] = useState(false);

  const copyToClipboard = (copyText) => {
    // logEvent(analytics, "copied_text");
    const cleanedSoapNote = copyText?.toString().replace(/\*/g, "");

    let text = "";
    text += cleanedSoapNote;
    navigator.clipboard.writeText(text);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1500); // reset copied text after 2 seconds
  };
  return (
    <div
      className={`w-full  ${
        formattedPromptSOAP
          ? "md:w-[60%] grid grid-cols-1 md:grid-cols-2 gap-4"
          : "md:w-[34%]"
      }`}
    >
      <div className="">
        <div className="flex justify-between items-center p-2">
          <p className="font-bold text-left">
            {!formattedSOAP
              ? "SOAP or Consultation Note"
              : formattedPromptSOAP
              ? "Accurate SOAP Note"
              : "Consultation Note"}
          </p>
          <PrimaryButton
            onClick={() => copyToClipboard(formattedSOAP)}
            label={"Copy Note"}
          />
        </div>

        <div className="p-2">
          <TextFormatter text={formattedSOAP} />
        </div>
      </div>
      {formattedPromptSOAP && (
        <div className="border border-l ">
          <div className="flex justify-between items-center p-2">
            <p className="font-bold text-left">Comprehensive SOAP Note</p>
            <PrimaryButton
              onClick={() => copyToClipboard(formattedPromptSOAP)}
              label={"Copy Note"}
            />
          </div>

          <div className="p-2">
            <TextFormatter text={formattedPromptSOAP} />
          </div>
        </div>
      )}
    </div>
  );
}

const TextFormatter = ({ text }) => {
  // Regular expression to match text between **
  const boldRegex = /\*\*(.*?)\*\*/g;
  text = text?.toString();
  // Replace matched text with <strong> tags
  const formattedText = text?.replace(
    boldRegex,
    (match, p1) =>
      `<div  class="my-2 border-customBlack-lighter"><br /><strong class="font-bold">${p1}</strong><br /></div>`
  );

  // Render the formatted text using dangerouslySetInnerHTML
  return (
    <div className=" " dangerouslySetInnerHTML={{ __html: formattedText }} />
  );
};
