import React from "react";
import Hero from "./sections/Hero";
import HowItWorks from "./sections/HowItWorks";
import Pricing from "./sections/Pricing";
import Features from "./sections/Features";
import Testimonials from "./sections/Testimonials";
import Footer from "./sections/Footer";
import "../../styles/LandingPage.css";
import HeroImg from "../../assets/hero.png";
import FAQ from "./sections/FAQ";

const LandingPage = () => {
  return (
    <div className="container mx-auto px-4 flex flex-col gap-8">
      <div className="text-center my-10 flex flex-col items-center">
        <p className="text-4xl md:text-[60px] leading-snug font-bold md:w-[60%]">
          Revolutionize Your Practice with MD Voice
        </p>
        <p className="text-xl mt-2 md:w-[47%] text-customBlack-secondary">
          Transform Doctor-Patient Conversations into Accurate, Automated SOAP
          Notes in Real-Time
        </p>
      </div>
      <div className="flex justify-center my-10">
        <img src={HeroImg} alt="Description" className="" />
      </div>
      <Features />
      <FAQ />
      <Pricing />

      <Footer />
    </div>
  );
};

export default LandingPage;
