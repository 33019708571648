import React from "react";
import "../../../styles/Footer.css";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <footer className="footer">
      <div className="flex justify-between px-2">
        <p>© 2024 MD Voice. All rights reserved.</p>
        <div>
          <li>
            <a href="/privacy-policy" target="_blank" className="text-blue-500">
              Privacy Policy
            </a>
          </li>
          <li>
            <a
              href="/terms-and-conditions"
              target="_blank"
              className="text-blue-500"
            >
              Terms & Conditions
            </a>
          </li>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
