import React, { useState, useRef, useEffect } from "react";
import { getAuth } from "firebase/auth";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import "../../styles/RecordingArea.css";
import Loader from "./Loader";
import axios from "axios";
import RecordGreenIcon from "../../assets/record-icon-green.png";
import { PrimaryButton, SecondaryButton } from "../Global/Button";
import useRecordingStore from "../../utils/recordingStore";
import { useNavigate } from "react-router-dom";
import useUserStore from "../../utils/userStore";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PatientInformation from "./PatientInformation";
import RecordingSection from "./RecordingSection";
import TranscriptSection from "./TranscriptSection";
import GeneratedPromptView from "./GeneratedPromptView";
import { trackEvent } from "../../firebase";

const RecordingArea = () => {
  const [isRecording, setIsRecording] = useState(false);
  const [pauseRecording, setPauseRecording] = useState(false);
  const { transcript, resetTranscript } = useSpeechRecognition();
  const [userTranscript, setUserTranscript] = useState("");
  const [patientName, setPatientName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [audioChunks, setAudioChunks] = useState([]);
  const streamRef = useRef(null);
  const [audioBlob, setAudioBlob] = useState(null);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [formattedSOAP, setFormattedSOAP] = useState();
  const [formattedPromptSOAP, setFormattedPromptSOAP] = useState();
  const setSearchName = useRecordingStore((state) => state.setSearchName);
  const [tempSearchName, setTempSearchName] = useState("");
  const [selectedOption, setSelectedOption] = useState("SOAP");
  const mediaRecorderRef = useRef(null);
  const [reloadData, setReloadData] = useState(false);
  const currentUser = useUserStore((state) => state.currentUser);
  const [selectedSpecialization, setSelectedSpecialization] = useState("");
  const [selectedLanguage, setSelectedLanguage] = useState("en-US");
  const speechRecognitionRef = useRef(null);

  useEffect(() => {
    if (selectedRecord?.id) setUserTranscript(selectedRecord?.transcript);
  }, [selectedRecord]);

  // Check if speech recognition is supported
  useEffect(() => {
    if (!SpeechRecognition.browserSupportsSpeechRecognition()) {
      toast.error("Browser doesn't support speech recognition.");
    }
  }, []);

  useEffect(() => {
    if (selectedRecord?.id) {
      setIsRecording(true);
      stopRecording();

      setFormattedSOAP(selectedRecord.soap_note);
      if (selectedRecord.soap_note1) {
        setFormattedPromptSOAP(selectedRecord.soap_note1);
      } else {
        setFormattedPromptSOAP("");
      }
      setPatientName(selectedRecord.patient_name);
    }
  }, [selectedRecord]);

  useEffect(() => {
    setUserTranscript(transcript);
  }, [transcript]);

  const startRecording = () => {
    setPauseRecording(true);
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        streamRef.current = stream;
        mediaRecorderRef.current = new MediaRecorder(stream);
        mediaRecorderRef.current.ondataavailable = (e) => {
          if (e.data.size > 0) {
            setAudioChunks((prev) => [...prev, e.data]);
          }
        };
        mediaRecorderRef.current.onstop = () => {
          if (audioChunks.length > 0) {
            setAudioBlob(new Blob(audioChunks, { type: "audio/wav" }));
          } else {
            console.warn("No audio data available");
          }
        };
        mediaRecorderRef.current.start();

        // Ensure compatibility with different browsers
        const SpeechRecognition =
          window.SpeechRecognition || window.webkitSpeechRecognition;
        if (SpeechRecognition) {
          const recognition = new SpeechRecognition();
          recognition.lang = selectedLanguage;
          recognition.continuous = true;

          recognition.onresult = (event) => {
            let newTranscript = "";
            for (let i = event.resultIndex; i < event.results.length; i++) {
              newTranscript += `${event.results[i][0].transcript} \n`;
            }
            setUserTranscript((prev) => prev + newTranscript);
            console.log("Transcript:", newTranscript);
            // You can add code here to handle the transcript
          };

          recognition.onerror = (event) => {
            console.error("Speech Recognition Error", event.error);
          };
          recognition.start();
          speechRecognitionRef.current = recognition;
        } else {
          console.error(
            "Speech Recognition API is not supported in this browser."
          );
        }
      })
      .catch((error) => {
        console.error("Error accessing media devices.", error);
        setIsRecording(false);
      });
  };

  const stopRecording = () => {
    setPauseRecording(false);
    if (
      mediaRecorderRef.current &&
      mediaRecorderRef.current.state !== "inactive"
    ) {
      mediaRecorderRef.current.stop();
    }
    if (speechRecognitionRef.current) {
      speechRecognitionRef.current.stop();
    }
    if (streamRef.current) {
      streamRef.current.getTracks().forEach((track) => track.stop());
    }
  };

  const resetRecordingState = () => {
    stopRecording();
    setAudioChunks([]);
    setAudioBlob(null);
    resetTranscript();
    setPatientName("");
    setSelectedSpecialization("");
    setUserTranscript("");
    setFormattedSOAP();
    setFormattedPromptSOAP();
    setIsRecording(false);
    setSelectedRecord(null);
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    stopRecording();
    const auth = getAuth();
    const user = auth.currentUser;

    const storage = getStorage();
    const storageRef = ref(
      storage,
      `audios/${user.uid}_${patientName}_${Date.now()}.wav`
    );

    try {
      const snapshot = await uploadBytes(storageRef, audioBlob);
      const audioURL = await getDownloadURL(snapshot.ref);

      const response = await axios.post(
        `${process.env.REACT_APP_BE_DOMAIN}/audio`,
        {
          transcript: userTranscript,
          audioUrl: audioURL,
          patientName: patientName,
          userId: user.uid,
          noteType: selectedOption,
          selectedSpecialization: selectedSpecialization,
          physicianName: currentUser.name,
          headers: {
            "Content-Type": "multipart/form-data",
            // 'Authorization': `Bearer ${token}`
          },
        }
      );

      if (response.data) {
        console.log("🚀 ~ handleSubmit ~ response.data:", response.data);
        // setSoapNotes(response.data.soapNotes);
        trackEvent("User", "Recording", "Creating new Recording");

        setFormattedSOAP(response.data.soapNotes);
        if (response.data.soapNotes1) {
          setFormattedPromptSOAP(response.data.soapNotes1);
        } else {
          setFormattedPromptSOAP("");
        }
      }

      toast.success("Audio and transcript submitted successfully");

      setReloadData(true);
    } catch (error) {
      console.error("Error submitting data:", error);
      toast.error("Failed to submit data.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleReSubmit = async (transcriptId) => {
    setIsLoading(true);
    stopRecording();
    const auth = getAuth();
    const user = auth.currentUser;

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BE_DOMAIN}/audio/update`,
        {
          transcriptId: transcriptId,
          transcript: userTranscript,
          userId: user.uid,
          physicianName: currentUser.name,
          headers: {
            "Content-Type": "multipart/form-data",
            // 'Authorization': `Bearer ${token}`
          },
        }
      );

      if (response.data) {
        console.log("🚀 ~ handleSubmit ~ response.data:", response.data);
        // setSoapNotes(response.data.soapNotes);
        trackEvent("User", "Recording", "Updated Recording");

        setFormattedSOAP(response.data.soapNotes);
        if (response.data.soapNotes1) {
          setFormattedPromptSOAP(response.data.soapNotes1);
        } else {
          setFormattedPromptSOAP("");
        }
      }

      toast.success("Audio and transcript submitted successfully");

      setReloadData(true);
    } catch (error) {
      console.error("Error submitting data:", error);
      toast.error("Failed to submit data.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleNameSearch = () => {
    setSearchName(tempSearchName);
  };

  const handleTranscriptChange = (e) => {
    setUserTranscript(e.target.value);
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  return (
    <RecordingAreaLocked
      isLocked={currentUser?.status === "cancel" ? true : false}
    >
      {isLoading && <Loader showText={true} />}
      <div className="flex flex-wrap">
        <RecordingSection
          handleNameSearch={handleNameSearch}
          reloadData={reloadData}
          selectedRecord={selectedRecord}
          setSelectedRecord={setSelectedRecord}
          tempSearchName={tempSearchName}
          setTempSearchName={setTempSearchName}
        />

        <div className="w-full md:w-[77%] flex justify-center order-1 md:order-2">
          {!isRecording ? (
            <PatientInformation
              patientName={patientName}
              setIsRecording={setIsRecording}
              setPatientName={setPatientName}
              setPauseRecording={setPauseRecording}
              setSelectedLanguage={setSelectedLanguage}
              startRecording={startRecording}
            />
          ) : (
            <div className="w-full">
              <div className="flex justify-between py-4 px-2 items-center border-b border-x-customBlack-light">
                <div>
                  <p className="text-xl font-semibold">{patientName}</p>
                </div>
                <div className="flex gap-2 items-center">
                  {selectedRecord?.id && (
                    <>
                      <a
                        href={selectedRecord?.audio_url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={RecordGreenIcon}
                          alt="record icon"
                          className="cursor-pointer"
                        />
                      </a>
                      <SecondaryButton
                        label={"Start new Recording"}
                        onClick={resetRecordingState}
                      />
                    </>
                  )}
                </div>
              </div>
              <div className="flex flex-wrap">
                <TranscriptSection
                  selectedRecord={selectedRecord}
                  startRecording={startRecording}
                  pauseRecording={pauseRecording}
                  userTranscript={userTranscript}
                  formattedPromptSOAP={formattedPromptSOAP}
                  stopRecording={stopRecording}
                  selectedOption={selectedOption}
                  handleOptionChange={handleOptionChange}
                  handleSubmit={handleSubmit}
                  handleReSubmit={handleReSubmit}
                  resetRecordingState={resetRecordingState}
                  setSelectedSpecialization={setSelectedSpecialization}
                  handleTranscriptChange={handleTranscriptChange}
                />
                <GeneratedPromptView
                  formattedPromptSOAP={formattedPromptSOAP}
                  formattedSOAP={formattedSOAP}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <ToastContainer />
    </RecordingAreaLocked>
  );
};

const RecordingAreaLocked = ({ isLocked, children }) => {
  const [dialog, setDialog] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (isLocked) openDialog();
  }, [isLocked]);

  const openDialog = () => {
    setDialog(true);
  };

  return (
    <div className="relative">
      {isLocked && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center">
          <div className="bg-white p-6 rounded flex  flex-col justify-center items-center gap-3 shadow-lg w-1/2">
            <p className="text-lg">
              Please subscribe to a plan to continue using MD Voice
            </p>

            <PrimaryButton
              label={"Get Started"}
              onClick={() => navigate("/pricing")}
            />
          </div>
        </div>
      )}
      {children}
    </div>
  );
};

export default RecordingArea;
